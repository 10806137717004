import React from 'react';

interface Props {
  title: string;
}

export const NotFoundFallback: React.FC<Props> = (props) => {
  let { title, children } = props;

  return (
    <div className="flex flex-col justify-center items-center py-16">
      <img src="/illustrations/empty-box.svg" className="max-w-md" />
      <div className="mt-16 font-medium text-2xl">{title}</div>
      <div className="mt-4">{children}</div>
    </div>
  );
};
