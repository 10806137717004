import { useTranslator } from '@translator-app/react';
import { ErrorFallback } from '~/app/error/error';
import { Button } from '~/components/button';
import Heading from '~/components/heading';
import HideUnauthorized from '~/components/hide-unauthorized';
import PageSpinner from '~/components/page-spinner';
import Pagination from '~/components/pagination';
import { SelectionCard } from '~/components/selection-card';
import TextField from '~/components/textfield';
import { useApplicationContext } from '~/contexts/application-context';
import { ShopsQuery, useSearchShopsQuery, useShopsQuery } from '~/generated/graphql';
import { usePaginationQuery } from '~/hooks/use-pagination';
import { useRouter } from 'next/router';
import React from 'react';

import { AuthRolesEnum } from '../../enums';
import { OrganisationPageWrapper } from '../../templates/organisation-page';

type Shop = ShopsQuery['shops']['docs'][0];

interface ShopsGridProps {
  shops: Array<Shop>;
  isLoading: boolean;
  error: any;
}

const ShopsGrid: React.FC<ShopsGridProps> = (props) => {
  const router = useRouter();
  let { organisation } = useApplicationContext();
  const { isLoading, error, shops } = props;

  if (isLoading) {
    return <PageSpinner />;
  }

  if (error) {
    console.log(error);
    return <ErrorFallback />;
  }

  return (
    <div className="grid gap-4 grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 py-4">
      {shops.map((shop) => {
        return (
          <SelectionCard
            key={shop._id}
            title={shop.name}
            onPress={() => {
              router.push('/[organisationId]/[shopId]', `/${organisation._id}/${shop._id}`);
            }}
            testId={`shop-card-${shop._id}`}
          >
            <div className="text-gray-600 text-sm">
              <div>{shop.phonenumber}</div>
              <div>{shop.email}</div>
              <div>
                {shop.address?.street} {shop.address?.streetNumber}
              </div>
              <div>
                {shop.address?.zipcode} {shop.address?.city}
              </div>
              <div>{shop.address?.country}</div>
            </div>
          </SelectionCard>
        );
      })}
    </div>
  );
};

const Page: React.FC = (props) => {
  let router = useRouter();
  let { query } = router;
  let { translate } = useTranslator();
  let pagination = usePaginationQuery();
  let { organisation } = useApplicationContext();
  let [listShopsResult] = useShopsQuery();

  let [searchQuery, setSearchQuery] = React.useState('');
  let [searchResults] = useSearchShopsQuery({
    variables: {
      query: searchQuery,
    },
  });

  const { shops, isLoading, error } = React.useMemo(() => {
    if (searchQuery) {
      const results = searchResults.data?.findShops || [];
      return {
        shops: results,
        isLoading: !!(searchResults.fetching && results.length),
        error: searchResults.error,
      };
    } else {
      const results = listShopsResult.data?.shops?.docs || [];
      return {
        shops: results,
        isLoading: !!(listShopsResult.fetching && results.length),
        error: listShopsResult.error,
      };
    }
  }, [searchResults, listShopsResult, searchQuery]);

  const totalShops = listShopsResult.data?.shops.total || 0;
  return (
    <div className="p-4 max-w-screen-xl mx-auto">
      <div className="flex flex-wrap justify-between">
        <Heading>{translate('shopScreen.heading')}</Heading>
        <div className="flex">
          <HideUnauthorized role={AuthRolesEnum.Admin}>
            <div>
              <Button
                appearance="default"
                href="/[organisationId]/settings/general"
                as={`/${organisation._id}/settings/general`}
              >
                {translate('shopScreen.organisationSettings')}
              </Button>
            </div>
          </HideUnauthorized>
          <HideUnauthorized role={AuthRolesEnum.Dealer}>
            <div className="ml-4">
              <Button
                appearance="primary"
                href="/[organisationId]/shops/create"
                as={`/${organisation._id}/shops/create`}
              >
                {translate('shopScreen.create')}
              </Button>
            </div>
          </HideUnauthorized>
        </div>
      </div>
      <div>
        <TextField placeholder={translate('shopScreen.search')} onChange={setSearchQuery} value={searchQuery} />
      </div>

      <ShopsGrid shops={shops} isLoading={isLoading} error={error} />

      {!searchQuery && (
        <Pagination
          skip={pagination.skip}
          limit={pagination.limit}
          totalItems={totalShops}
          onPageSelect={({ skip, limit }) => {
            router.push(
              { pathname: '/[organisationId]', query: { ...query, skip, limit } },
              { pathname: `/${organisation._id}`, query: { ...query, skip, limit } }
            );
          }}
        />
      )}
    </div>
  );
};

function Index() {
  return (
    <OrganisationPageWrapper title="Shops">
      <Page />
    </OrganisationPageWrapper>
  );
}

export default Index;
