import React from 'react';

import { OrganisationProvider } from '../contexts/organisation-context';
import { PageWrapper } from './page';

export type Props = {
  children: React.ReactNode;
  title?: string;
};

export const OrganisationPageWrapper: React.FC<Props> = (props) => {
  let { children, title } = props;

  return (
    <PageWrapper title={title}>
      <OrganisationProvider>{children}</OrganisationProvider>
    </PageWrapper>
  );
};
