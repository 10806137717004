import { ErrorFallback } from '~/app/error/error';
import { NotFoundFallback } from '~/app/error/not-found-fallback';
import PageSpinner from '~/components/page-spinner';
import { useRouter } from 'next/router';
import * as React from 'react';

import { useCurrentOrganisationQuery } from '../generated/graphql';
import { getErrorMessageFromError } from '../utils/error-message';
import { setSentryOrganisation } from '../utils/sentry';
import { CurrentOrganisation, useApplicationContext } from './application-context';
import createContext from './create-context';
import { useGraphqlClient } from './graphql-context';

type ProviderProps = {
  children: React.ReactNode;
};

type ProviderValue = {
  currentOrganisation: CurrentOrganisation;
};

let [useContext, ReactProvider, ReactConsumer] = createContext<ProviderValue>();

export function OrganisationProvider(props: ProviderProps) {
  let { children } = props;
  let router = useRouter();
  let organisationId = router.query?.organisationId || '';
  let { organisationHeader } = useGraphqlClient();
  let { organisation, setOrganisation } = useApplicationContext();

  let pauseFetching = !organisationId || organisationId !== organisationHeader;
  let [{ data, error, fetching }] = useCurrentOrganisationQuery({
    pause: pauseFetching,
  });

  React.useEffect(() => {
    let currentOrganisation = data?.getCurrentOrganisation;
    if (currentOrganisation) {
      setSentryOrganisation(currentOrganisation);
    }
    setOrganisation(currentOrganisation);
  }, [data]);

  if (!organisation || organisation?._id !== organisationHeader) {
    if (fetching) {
      return <PageSpinner />;
    } else if (error) {
      return <ErrorFallback message={getErrorMessageFromError(error)} />;
    } else {
      return <NotFoundFallback title="Organisation not found" />;
    }
  }

  return (
    <ReactProvider
      value={{
        currentOrganisation: organisation,
      }}
    >
      {children}
    </ReactProvider>
  );
}

export const useOrganisationContext = useContext;
export const OrganisationConsumer = ReactConsumer;
